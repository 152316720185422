import Util from "../utils/Util";
import Behaviour from "./Behaviour";

export default class CrossZone extends Behaviour {
  /**
   * Defines what happens if the particles come to the end of the specified zone
   *
   * @memberof! Proton#
   * @augments Proton.Behaviour
   * @constructor
   * @alias Proton.CrossZone
   *
   * @param {Proton.Zone} zone 						can be any Proton.Zone - e.g. Proton.RectZone()
   * @param {String} 		[crossType=dead] 			what happens if the particles pass the zone - allowed strings: dead | bound | cross
   * @param {Number} 		[life=Infinity] 			this behaviour's life
   * @param {String} 		[easing=ease.easeLinear] 	this behaviour's easing
   *
   * @property {String} name The Behaviour name
   */
  constructor(zone, crossType, life, easing) {
    super(life, easing);

    this.reset(zone, crossType);
    this.name = "CrossZone";
  }

  /**
   * Reset this behaviour's parameters
   *
   * @method reset
   * @memberof Proton#Proton.CrossZone
   * @instance
   *
   * @param {Proton.Zone} zone 				can be any Proton.Zone - e.g. Proton.RectZone()
   * @param {String} 		[crossType=dead] 	what happens if the particles pass the zone - allowed strings: dead | bound | cross
   * @param {Number} 		[life=Infinity] 	this behaviour's life
   * @param {String} 		[easing=easeLinear]	this behaviour's easing
   */
  reset(zone, crossType, life, easing) {
    this.zone = zone;
    this.zone.crossType = Util.initValue(crossType, "dead");

    life && super.reset(life, easing);
  }

  /**
   * Apply this behaviour for all particles every time
   *
   * @method applyBehaviour
   * @memberof Proton#Proton.CrossZone
   * @instance
   *
   * @param {Proton.Particle} particle
   * @param {Number} the integrate time 1/ms
   * @param {Int} the particle index
   */
  applyBehaviour(particle, time, index) {
    this.calculate(particle, time, index);
    this.zone.crossing(particle);
  }
}
